import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  getDocLinkType,
  sendDocLink,
  getDocLink,
  getDownloadFileLink,
  getProductFields,
} from "../../actions/NdiFieldsAction";
import { Validations } from "../../utils/validations";
import Toastr from "../../messages";
import Loader from "../../loader";

import MuiAlert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import IconButton from "@material-ui/core/IconButton";
import Select, { StylesConfig } from "react-select";

const filter = createFilterOptions();

let FIELD_VALIDATION = {};
FIELD_VALIDATION["id"] = {
  check: true,
  chkType: "int",
  message: "incorrect value",
};
FIELD_VALIDATION["name"] = {
  check: true,
  chkType: "String",
  message: "incorrect value",
};
FIELD_VALIDATION["text_label"] = {
  check: false,
  chkType: "String",
  message: "incorrect value",
};
FIELD_VALIDATION["placeholder"] = {
  check: false,
  chkType: "String",
  message: "incorrect value",
};
FIELD_VALIDATION["field"] = {
  check: true,
  chkType: "String",
  message: "incorrect value",
};
FIELD_VALIDATION["field_type"] = {
  check: false,
  chkType: "String",
  message: "incorrect value",
};
FIELD_VALIDATION["field_default_value"] = {
  check: false,
  chkType: "String",
  message: "incorrect value",
};
FIELD_VALIDATION["mask"] = {
  check: false,
  chkType: "String",
  message: "incorrect value",
};
FIELD_VALIDATION["field_service"] = {
  check: false,
  chkType: "String",
  message: "incorrect value",
};
FIELD_VALIDATION["field_dataset"] = {
  check: false,
  chkType: "String",
  message: "incorrect value",
};
FIELD_VALIDATION["tag_id"] = {
  check: false,
  chkType: "int",
  message: "incorrect value",
};

class PublicLinkForm extends React.Component {
  validation = new Validations();
  actionMode = "new";
  form_name = "";
  target = "";
  listFormOptions = {};

  constructor(props) {
    super(props);
    this.state = {
      isLoaderOpen: false,
      toastrMessage: "",
      toastrType: "",
      toastrDuration: 6000,
      id: 0,
      name: "",
      e_name: false,
      text_label: "",
      e_text_label: false,
      placeholder: "",
      e_placeholder: false,
      field: "",
      e_field: false,
      field_type: "",
      e_field_type: false,
      field_default_value: "",
      e_field_default_value: false,
      mask: "",
      e_mask: false,
      field_service: "",
      e_field_service: false,
      field_dataset: "",
      e_field_dataset: false,
      tag_id: "",
      e_tag_id: false,
      docList: [],
      hideSendButton: true,
      hideButtonlink: false,
      message: "",
      formurl: "",
      action: "",
      mediaToSendToList: [],
      mediatosend: "",
      selOptionTxt: "",
      valueClientSearch: "",
      inputValueClientSearch: "",
      objectClientSearch: null,
      keyreload: "000",
      name2: {},
      dynamicFormAllData: {},
      fullticket: {},
      listEmails: [],
    };
    const locurl = window.location.href;
    const patt = /([0-9]+)(\/edit)$/;
    if (patt.test(locurl)) {
      this.actionMode = "edit";
    } else {
      this.actionMode = "new";
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeDocList = (event) => {
    this.form_name = "";
    this.target = "";
    const form_name = event.target[event.target.selectedIndex].dataset.formname;
    const target = event.target[event.target.selectedIndex].dataset.target;
    const ticket = this.props.currentTicket;
    this.setState({ [event.target.name]: event.target.value });
    if (form_name !== undefined && target !== undefined) {
      this.form_name = form_name;
      this.target = "" + target;
    }

    this.setState({
      ["isLoaderOpen"]: false,
      ["hideSendButton"]: true,
      ["message"]: "",
      ["formurl"]: "",
      ["hideButtonlink"]: false,
    });
  };

  handleChangeDocList2 = (event) => {
    this.form_name = "";
    this.target = "";
    const backVal = event.value;

    this.setState({ ["form_name"]: "" });

    if (backVal !== undefined && backVal !== null && backVal !== "") {
      let locObjItem = {};
      let locObj = {};
      for (let item of this.listFormOptions) {
        if (item.value === backVal) {
          locObjItem = item;
          break;
        }
      }
      if (locObjItem !== null && locObjItem.value !== undefined) {
        locObj = JSON.parse(locObjItem.valuex);
      }
      const form_name = locObj.formname;
      const target = locObj.target;
      const value = locObj.value;
      const ticket = this.props.currentTicket;

      this.setState({ ["name"]: value, ["name2"]: locObj });
      if (form_name !== undefined && target !== undefined) {
        this.form_name = form_name;
        this.target = "" + target;
        this.setState({ ["form_name"]: "" + form_name });
      }

      //zqwNFFrmZn010_x

      this.setState({
        ["isLoaderOpen"]: false,
        ["hideSendButton"]: true,
        ["message"]: "",
        ["formurl"]: "",
        ["hideButtonlink"]: false,
      });
    }
  };

  handleChangeXTraEmails = (event) => {
    const listEmails = this.state.listEmails;
    //const index =event.value;
    this.setState({ [event.target.name]: event.target.value });

    let index = -3;
    if (
      event.target.dataset !== undefined &&
      event.target.dataset.index !== undefined
    ) {
      index = event.target.dataset.index;
    }

    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const aaaaEmail = "" + event.target.value;
    if (index >= 0) {
      //if(listEmails.length-1))
      if (listEmails[index] !== undefined) {
        listEmails[index] = "" + event.target.value;
      } else {
        listEmails.push("" + event.target.value);
      }
      this.setState({ ["listEmails"]: listEmails });
    }
  };

  handleChangeXTraEmailsrem = (event) => {
    const listEmails = this.state.listEmails;

    let index = -3;
    if (
      event.target.dataset !== undefined &&
      event.target.dataset.index !== undefined
    ) {
      index = event.target.dataset.index;
    }

    if (index >= 0) {
      listEmails.splice(index, 1);

      this.setState({ ["listEmails"]: listEmails });
    }
  };

  handleChangeXTraEmailsadd = (event) => {
    const listEmails = this.state.listEmails;
    listEmails.push("");
    this.setState({ ["listEmails"]: listEmails });
  };

  handleFocusout = (event) => {
    if (
      FIELD_VALIDATION[event.target.name] !== undefined &&
      FIELD_VALIDATION[event.target.name].check === true
    ) {
      this.setState({ ["e_" + event.target.name]: false });
      if (
        !this.validation.validate(
          FIELD_VALIDATION[event.target.name].chkType,
          event.target.value
        )
      ) {
        this.setState({ ["e_" + event.target.name]: true });
      }
    }
  };

  componentDidMount() {
    const { t } = this.props;
    if (this.actionMode === "edit") {
      if (this.props.NdiFields.id === undefined) {
        //means some refresh happens  id
        const idx = this.props.match.params.id;
        Promise.resolve(this.props.getNdiFields(idx)).then((response) => {
          this.setState(response.NdiFieldsOne);
          return response;
        });
      }
    }

    const ticket_id = this.props.currentTicket;
    const objToSend = { id: 0, ticketId: ticket_id }; //{id:4, ticketId:""}; CASE ID: 369 PROD ID: 4
    this.loadProductsFields(objToSend);
    const formOrFile = this.props.formOrFile;
    const publicFormOrFile = this.props.publicFormOrFile;
    if (formOrFile === 2) {
    }

    const docList = this.state.docList;
    if (docList.length === 0 && formOrFile !== 2) {
      // NdiFieldsOne
      const this2 = this;
      this.setState({ ["isLoaderOpen"]: true });
      Promise.resolve(this.props.getDocLinkType())
        .then((response) => {
          const listForms = response.NdiFieldsOne;
          //this.setState(response.NdiFieldsOne);
          this.setState({ ["isLoaderOpen"]: false, ["docList"]: listForms });
          return response;
        })
        .catch(function (error) {
          this2.setState({ ["isLoaderOpen"]: false });
          const errObj = error;
          let errMessage =
            errObj.data !== ""
              ? "Erreur " + " \n" + errObj.data
              : "Erreur " + " \n" + errObj.statusText;
          if (errObj.status === undefined) {
            errMessage += "\n " + t("zqwNVFrmEl14_x");
          }
          this2.showToast(errMessage, "error");
        });
    }

    const dynamicFormAllData = { optionsData: [] };
    this.putOnHotTicketData(dynamicFormAllData);
  }

  showToast = (arg, toastType) => {
    this.setState({
      // update a property
      toastrMessage: arg,
      toastrType: toastType,
    });
    setTimeout(() => {
      this.setState({
        toastrMessage: "",
        toastrType: "success",
      });
    }, 5500);
  };

  handleSubmitDoc = (event) => {
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const name = this.state.name;

    const form_name = this.form_name;
    const target = this.target;
    const ticket = this.props.currentTicket;
    const action = this.state.action;
    const formurl = this.state.formurl;

    let selOptionTxt = "";
    let passValid = 0;
    let mediatosend = this.state.mediatosend;
    const valueClientSearch = this.state.valueClientSearch;
    const inputValueClientSearch = this.state.inputValueClientSearch;
    const objectClientSearch = this.state.objectClientSearch;
    let emailphone = "";

    if (objectClientSearch !== null) {
      selOptionTxt = objectClientSearch.label;
      emailphone = "" + objectClientSearch.value;
      mediatosend = objectClientSearch.mediatype;
    }
    if (mediatosend !== "") {
      if (mediatosend === "email") {
        if (
          objectClientSearch === null &&
          !this.validateEmail(inputValueClientSearch)
        ) {
          window.alert(t("zqwerrorFoundonform_x") + " " + t("zqwNFFrmZn018_x"));
          this.showToast(
            t("zqwerrorFoundonform_x") + " " + t("zqwNFFrmZn018_x"),
            "error"
          );
          return false;
        }
        passValid = 1;
      } else {
        if (
          objectClientSearch === null &&
          !this.validatePhone(inputValueClientSearch)
        ) {
          window.alert(t("zqwerrorFoundonform_x") + " " + t("zqwNFFrmZn018_x"));
          this.showToast(
            t("zqwerrorFoundonform_x") + " " + t("zqwNFFrmZn018_x"),
            "error"
          );
          return false;
        }
        passValid = 2;
      }
    } else {
    }
    if (objectClientSearch && typeof objectClientSearch === 'string' && inputValueClientSearch) {
      emailphone = "" + inputValueClientSearch;
    }
    if (!emailphone) {
      window.alert(t("zqwNFFrmZn015_x"));
      this.showToast(t("zqwNFFrmZn015_x"), "error");
      return false;
    }

    if (this.validateEmail(emailphone)) {
      mediatosend = "email";
      passValid = 1;
    }

    // if (this.validatePhone(emailphone)) {
    //   mediatosend = "SMS";
    //   passValid = 2;
    // }

    if (passValid < 1) {
      window.alert(t("zqwerrorFoundonform_x") + " " + t("zqwNFFrmZn018_x"));
      this.showToast(
        t("zqwerrorFoundonform_x") + " " + t("zqwNFFrmZn018_x"),
        "error"
      );
      return false;
    }

    if (
      form_name === undefined ||
      form_name === null ||
      form_name === "" ||
      target === undefined ||
      target === null ||
      target === ""
    ) {
      window.alert(t("zqwerrorFoundonform_x") + " " + t("zqwNFFrmZn018_x"));
      this.showToast(
        t("zqwerrorFoundonform_x") + " " + t("zqwNFFrmZn018_x"),
        "error"
      );
      return false;
    }

    if (ticket === undefined || ticket === null) {
      window.alert(t("zqwerrorFoundonform_x") + " " + t("zqwNFFrmZn018_x"));
      this.showToast(
        t("zqwerrorFoundonform_x") + " " + t("zqwNFFrmZn018_x"),
        "error"
      );
      return false;
    }

    const listEmails = this.state.listEmails;
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let validEmails = 0;
    let invalidEmails = 0;
    const goListEmails = [];
    if (listEmails.length > 0) {
      for (const emailval of listEmails) {
        const aaaaEmail = "" + emailval;
        if (aaaaEmail.match(mailformat)) {
          validEmails++;
          goListEmails.push(aaaaEmail);
        } else if (aaaaEmail.length > 0) {
          invalidEmails++;
        }
      }

      if (validEmails === 0 || invalidEmails > 0) {
        this.showToast(
          "Il semble que la liste de courriel est vide ou il y a un courriel qui est incorrect. ",
          "error"
        );
        window.alert(
          "Il semble que la liste de courriel est vide ou il y a un courriel qui est incorrect. "
        );
        return false;
      }
    }

    const docLink = {
      form_name: form_name,
      target: target,
      ticketid: "" + ticket,
      formurl: formurl,
      action: mediatosend,
      emailphone: emailphone,
    };
    const this2 = this;
    docLink.sendfilelink = 0;
    const formOrFile = this.props.formOrFile;
    const publicFormOrFile = this.props.publicFormOrFile;
    if (formOrFile === 2) {
      docLink.sendfilelink = formOrFile;
    }
    if (validEmails > 0) {
      docLink.otherEmails = goListEmails;
    }

    this.setState({ ["isLoaderOpen"]: true });
    Promise.resolve(this.props.sendDocLink(docLink))
      .then((response) => {
        const listForms = response.NdiFieldsOne;
        let hideSendButton = false;
        let message = "";
        let message2 = "";
        if (
          listForms.puburl !== undefined &&
          listForms.puburl.url !== undefined &&
          listForms.puburl.url !== ""
        ) {
          hideSendButton = true;
          message = "<b>" + t("zqwNFFrmZn012_x") + "</b><br/>" + emailphone;
          message2 = "Doc Link " + listForms.puburl.url;
        }
        this.setState({
          ["isLoaderOpen"]: false,
          ["hideSendButton"]: hideSendButton,
          ["message"]: message,
        });
        this.setState({ ["listEmails"]: [] });

        if (this.props.paStateFuncAction !== undefined) {
          const locMess = "Neo " + message2;
          const locObj = { stateName: "sendPublicFormtxt", stateVal: locMess };
          this.props.paStateFuncAction(locObj);
        }

        this.showToast("Felicitations/Congratulations!", "success");
        return response;
      })
      .catch(function (error) {
        this2.setState({ ["isLoaderOpen"]: false });
        const errObj = error;
        let errMessage =
          errObj.data !== ""
            ? "Erreur " + " \n" + errObj.data
            : "Erreur " + " \n" + errObj.statusText;
        if (errObj.status === undefined) {
          errMessage += "\n " + t("zqwNVFrmEl14_x");
        }
        this2.showToast(errMessage, "error");
      });
  };

  handleSubmitGetLink = (event) => {
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const name = this.state.name;

    const form_name = this.form_name;
    const target = this.target;
    const ticket = this.props.currentTicket;
    const valueClientSearch = this.state.valueClientSearch;
    const inputValueClientSearch = this.state.inputValueClientSearch;
    const objectClientSearch = this.state.objectClientSearch;
    let emailphone = "";

    if (objectClientSearch && typeof objectClientSearch === 'object') {
      emailphone = "" + objectClientSearch.value;
    } else if (typeof objectClientSearch === 'string') {
      emailphone = objectClientSearch ? "" + objectClientSearch : "";
    }
    if (!emailphone) {
      window.alert(t("zqwNFFrmZn015_x"));
      this.showToast(t("zqwNFFrmZn015_x"), "error");
      return false;
    }

    if (
      form_name === undefined ||
      form_name === null ||
      form_name === "" ||
      target === undefined ||
      target === null ||
      target === ""
    ) {
      alert(t("zqwerrorFoundonform_x"));
      this.showToast(t("zqwerrorFoundonform_x"), "error");
      return false;
    }

    if (ticket === undefined || ticket === null) {
      alert(t("zqwerrorFoundonform_x") + " #ticket");
      this.showToast(t("zqwerrorFoundonform_x"), "error");
      return false;
    }

    let form_oldurl = "";
    let form_nameid = "";
    const docList = this.state.docList;
    if (docList.length > 0) {
      for (let item of docList) {
        if (
          item.form_name !== undefined &&
          item.form_name !== null &&
          item.form_name === form_name
        ) {
          if (
            (item.url !== undefined && item.url !== null) ||
            item.url !== ""
          ) {
            form_oldurl = "" + item.url;
          }
          form_nameid = "" + item.id;
        }
      }
    }

    const formOrFile = this.props.formOrFile;
    const publicFormOrFile = this.props.publicFormOrFile;
    let docxLink = this.props.getDocLink;
    if (formOrFile === 2) {
      docxLink = this.props.getDownloadFileLink;
    }

    const docLink = {
      form_name: form_name,
      target: target,
      ticketid: ticket,
      form_oldurl,
      emailphone,
    };
    if (formOrFile === 2) {
      docLink.url = publicFormOrFile.fileaddress;
    }

    docLink.id = "" + form_nameid;
    const this2 = this;
    this.setState({ ["isLoaderOpen"]: true });
    Promise.resolve(docxLink(docLink))
      .then((response) => {
        if (formOrFile === 2) {
          const listForms = response.NdiFieldsOne;
          let hideSendButton = true;
          let message = "";
          listForms.puburl = { url: listForms.url };
          if (
            listForms.puburl !== undefined &&
            listForms.puburl.url !== undefined &&
            listForms.puburl.url !== ""
          ) {
            hideSendButton = false;
          }

          this.setState({
            ["isLoaderOpen"]: false,
            ["hideSendButton"]: hideSendButton,
            ["message"]: message,
            ["formurl"]: listForms.puburl.url,
            ["hideButtonlink"]: true,
          });

          this.showToast("Felicitations/Congratulations!", "success");
        } else {
          const listForms = response.NdiFieldsOne;
          let hideSendButton = true;
          let message = "";
          if (
            listForms.puburl !== undefined &&
            listForms.puburl.url !== undefined &&
            listForms.puburl.url !== ""
          ) {
            hideSendButton = false;
          }
          this.setState({
            ["isLoaderOpen"]: false,
            ["hideSendButton"]: hideSendButton,
            ["message"]: message,
            ["formurl"]: listForms.puburl.url,
            ["hideButtonlink"]: true,
          });

          this.showToast("Felicitations/Congratulations!", "success");
        }
        return response;
      })
      .catch(function (error) {
        this2.setState({ ["isLoaderOpen"]: false });
        const errObj = error;
        let errMessage =
          errObj.data !== ""
            ? "Erreur " + " \n" + errObj.data
            : "Erreur " + " \n" + errObj.statusText;
        if (errObj.status === undefined) {
          errMessage += "\n " + t("zqwNVFrmEl14_x");
        }
        this2.showToast(errMessage, "error");
      });
  };

  validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  validatePhone = (txt) => {
    const re =
      /^([\(]*\+?[0-9]{1,3}[\)]*)?([\s]*)(\(?\+?)([0-9]{3})(\)?)([\s]*[\-]?)([0-9]{3})([\-]?)([0-9]{4})([\s]*)$/;

    return re.test(txt);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const name = this.state.name;
    const text_label = this.state.text_label;
    const placeholder = this.state.placeholder;
    const field = this.state.field;
    const field_type = this.state.field_type;
    const field_default_value = this.state.field_default_value;
    const mask = this.state.mask;
    const field_service = this.state.field_service;
    const field_dataset = this.state.field_dataset;
    const tag_id = this.state.tag_id;
    const NdiFields = {
      id: id,
      name: name,
      text_label: text_label,
      placeholder: placeholder,
      field: field,
      field_type: field_type,
      field_default_value: field_default_value,
      mask: mask,
      field_service: field_service,
      field_dataset: field_dataset,
      tag_id: tag_id,
    };

    let errorFound = 0;
    for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
      if (FIELD_VALIDATION[fieldName] !== undefined && obj.check === true) {
        this.setState({ ["e_" + fieldName]: false });
        let fieldValue = this.state[fieldName]; // ? this.state[fieldName] : this.props.NdiFields[fieldName];
        if (!this.validation.validate(obj.chkType, fieldValue)) {
          errorFound++;
          this.setState({ ["e_" + fieldName]: true });
        }
      }
    }
    if (errorFound > 0) {
      alert(`{t('zqwerrorFoundonform_x')}`);
      return false;
    }
    if (this.actionMode === "new") {
      this.props.addOrUpdateNdiFields(NdiFields, 1);
    } else {
      this.props.addOrUpdateNdiFields(NdiFields, 2);
    }
  };

  handleCancel = () => {
    if (this.actionMode === "new") {
      this.props.history.push(`/NdiFields`);
    } else {
      const idx = this.props.match.params.id;
      this.props.history.push(`/NdiFields/${idx}`);
    }
  };

  handleSetMediaOption = (event) => {
    const sendoption = event.currentTarget.dataset.sendoption;
    this.setState({ ["mediatosend"]: sendoption });
  };

  render() {
    const { t } = this.props;
    const open = this.props.openx;
    const myToastMessage = this.state.toastrMessage;
    const toastrType = this.state.toastrType;
    const Alert = this.Alert;
    const mediatosend = this.state.mediatosend;
    let listFormOptions = [];
    const form_name = this.form_name;
    const listEmails = this.state.listEmails;

    const docList = this.state.docList;
    let docListformat =
      docList.length > 0 &&
      docList.map((item, i) => {
        let title = "";
        if (
          item.title === undefined ||
          item.title === null ||
          item.title === ""
        ) {
          title = item.form_name;
        } else {
          title = item.title;
        }

        const locObj = {
          value: item.id,
          formname: item.form_name,
          target: item.target,
        };
        listFormOptions.push({
          value: item.id,
          label: title,
          valuex: JSON.stringify(locObj),
        });
        this.listFormOptions = listFormOptions;

        return (
          <option
            value={item.id}
            key={"dc" + i}
            data-formname={item.form_name}
            data-target={item.target}
          >
            {title}
          </option>
        );
      }, this);

    const hideButton = this.state.hideSendButton;
    const messageSrv = this.state.message;
    const formurl = this.state.formurl;
    const hideButtonlink = this.state.hideButtonlink;
    const mediaToSendToList = this.state.mediaToSendToList;
    const options = [];
    let mediaList =
      mediaToSendToList.length > 0 &&
      mediaToSendToList.map((item, i) => {
        if (mediatosend !== "") {
          if (mediatosend === item.media) {
            options.push({
              mediatype: item.media,
              role: item.role,
              value: item.endmedia,
              name: item.fname + " " + item.lname,
              label:
                item.role +
                " " +
                item.fname +
                " " +
                item.lname +
                " " +
                item.media +
                ":" +
                item.endmedia,
            });
            return (
              <option
                value={item.endmedia}
                key={"meddc" + i}
                data-mediatype={item.media}
                data-role={item.role}
                data-name={item.fname + " " + item.lname}
              >
                {item.role +
                  " " +
                  item.fname +
                  " " +
                  item.lname +
                  " " +
                  item.media +
                  ":" +
                  item.endmedia}
              </option>
            );
          } else {
            return null;
          }
        }

        options.push({
          mediatype: item.media,
          role: item.role,
          value: item.endmedia,
          name: item.fname + " " + item.lname,
          label:
            item.role +
            " " +
            item.fname +
            " " +
            item.lname +
            " " +
            item.media +
            ":" +
            item.endmedia,
        });
        return (
          <option
            value={item.endmedia}
            key={"meddc" + i}
            data-mediatype={item.media}
            data-role={item.role}
            data-name={item.fname + " " + item.lname}
          >
            {item.role +
              " " +
              item.fname +
              " " +
              item.lname +
              " " +
              item.media +
              ":" +
              item.endmedia}
          </option>
        );
      }, this);

    let emailCls = "",
      smsCls = "";
    let selOptionTxt = "";
    const valueClientSearch = this.state.valueClientSearch;
    const inputValueClientSearch = this.state.inputValueClientSearch;
    const objectClientSearch = this.state.objectClientSearch;
    if (objectClientSearch !== null) {
      selOptionTxt = objectClientSearch.label;
    }
    if (mediatosend !== "") {
      if (mediatosend === "email") {
        emailCls = " fxmac ";
        smsCls = "";
      } 
      // else {
      //   emailCls = "";
      //   smsCls = " fxmac ";
      // }
    }

    const keyreload = this.state.keyreload;
    const formKey = "" + keyreload;
    const form_nameZx = this.state.form_name;

    const formOrFile = this.props.formOrFile;
    const publicFormOrFile = this.props.publicFormOrFile;
    const name2 = this.state.name2;
    const name = this.state.name;
    let nameValSel = null;

    if (formOrFile === 2) {
      this.form_name = "gofile";
      this.target = "gofile";
    }

    return (
      <div key={formKey} style={{ minWidth: "750px" }}>
        <form>
          {formOrFile === 1 ? (
            <div className="form-group">
              <label htmlFor="name">{t("zqwNFFrmZn010_x")}</label>
              <Select
                defaultValue={name2}
                options={listFormOptions}
                onChange={this.handleChangeDocList2}
              />
              {this.state.e_name && (
                <span className="text-danger">
                  {FIELD_VALIDATION["name"].message}
                </span>
              )}
            </div>
          ) : (
            ""
          )}
          <div className="form-group">
            <div className="form-group">
              <label htmlFor="mediatosend">{t("zqwNFFrmZn014_x")}</label>
            </div>
            <div className="row col-12">
              <Autocomplete
                value={valueClientSearch}
                inputValue={inputValueClientSearch}
                onInputChange={(event, newInputValue) => {
                  if (event?.nativeEvent?.bubbles === true) {
                    this.setState({
                      ["inputValueClientSearch"]: newInputValue,
                    });
                  }
                  if (newInputValue.length > 2) {
                    setTimeout(() => {
                      console.log("neoVaaaaaaaaaaal ", newInputValue);
                    }, 0);
                  }
                }}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    setTimeout(() => {
                      this.setState({
                        ["valueClientSearch"]: newValue,
                        ["valueClientSearch"]: newValue,
                      });
                    });
                    this.setState({
                      ["valueClientSearch"]: newValue,
                      ["objectClientSearch"]: newValue,
                    });
                  } else if (newValue && newValue.inputValue) {
                    this.setState({
                      ["valueClientSearch"]: newValue.inputValue,
                      ["objectClientSearch"]: newValue,
                    });
                  } else {
                    this.setState({
                      ["valueClientSearch"]: "",
                      ["objectClientSearch"]: newValue,
                    });
                  }
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={options}
                renderOption={(option) => option.label}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.label;
                }}
                style={{ minWidth: 400 }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("zqwNFFrmZn016_x")}
                    variant="outlined"
                  />
                )}
              />
            </div>
            <span style={{ fontWeight: "bolder", color: "blue" }}>
              {selOptionTxt}
            </span>
          </div>
          {formOrFile === 2 ? (
            <div className="form-group">
              <label htmlFor="name">{t("zqwNFFrmZn010b_x")}</label>
              <br />
              {publicFormOrFile.labelname}
            </div>
          ) : (
            ""
          )}
          <div className="form-group">
            <textarea
              id="formurl"
              name="formurl"
              className={"form-control"}
              rows="4"
              cols="50"
              defaultValue={formurl}
            ></textarea>
          </div>
          <>
            {form_nameZx?.indexOf("testament-") > -1 ? (
              <div>
                <div className="form-group">
                  <label htmlFor="emailextras">
                    Autres Emails (Testament)
                    <IconButton
                      edge="end"
                      aria-label="Add more/ajouter plus"
                      onClick={this.handleChangeXTraEmailsadd}
                    >
                      +
                    </IconButton>
                  </label>
                </div>
                {listEmails !== null &&
                  listEmails.map((option, ind) => (
                    <div className="row">
                      <div className="md-10">
                        <input
                          type="email"
                          name="emails"
                          id="emails"
                          defaultValue={option}
                          data-index={ind}
                          onChange={this.handleChangeXTraEmails}
                          // onBlur={this.handleFocusout}
                          className={"form-control"}
                          placeholder={"Email"}
                        />{" "}
                        &nbsp;
                      </div>
                      <div className="md-2">
                        <button
                          type="button"
                          className="btn btn-danger"
                          data-index={ind}
                          onClick={this.handleChangeXTraEmailsrem}
                        >
                          x
                        </button>{" "}
                        &nbsp;
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              ""
            )}
          </>
          <div className="form-group">
            <label htmlFor="name"> &nbsp; </label>
          </div>
          {hideButton === false ? (
            <div className="form-group">
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.handleSubmitDoc}
              >
                {t("zqwNFFrmZn011_x")}
              </button>{" "}
              &nbsp;
            </div>
          ) : (
            ""
          )}
          {hideButtonlink === false ? (
            <div className="form-group">
              <button
                disabled={objectClientSearch === null}
                type="button"
                className="btn btn-primary"
                onClick={this.handleSubmitGetLink}
              >
                {t("zqwNFFrmZn013_x")}
              </button>{" "}
              &nbsp;
            </div>
          ) : (
            ""
          )}
          {messageSrv !== "" ? (
            <div className="form-group">
              <div dangerouslySetInnerHTML={{ __html: messageSrv }}></div>
            </div>
          ) : (
            ""
          )}
          {/* {formOrFile === 1 ? (
            <div className="form-group">
              <Alert severity="warning">{t("zqwCViewEl13c_x")}</Alert>
            </div>
          ) : (
            ""
          )} */}
        </form>
        <Toastr messagex={myToastMessage} open={true} toastType={toastrType} />
        <Loader openx={this.state.isLoaderOpen} />
      </div>
    );
  }

  Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  loadProductsFields = (objToSend) => {
    const { t } = this.props;
    let currentTabMain = this.props.currentTab;
    if (currentTabMain === undefined) {
      currentTabMain = "tab1";
    }
    const this2 = this;
    objToSend.zendeskapi = 1;

    Promise.resolve(this.props.getProductFields(objToSend))
      .then((response) => {
        const clientProdTransInfo =
          response.NdiFieldsOne.data.clientProdTransInfo;
        const myClientId = clientProdTransInfo.client.id;
        const isClientLead = myClientId > 0 ? false : true;
        const client_id = response.NdiFieldsOne.data.client_id;
        const istherelawyer =
          response.NdiFieldsOne.data.isTherePALawyer === false ? false : true;
        const domainInLaw = response.NdiFieldsOne.data.domainInLaw;
        const client_type = response.NdiFieldsOne.data.client_type;
        this.setState({
          ["isClientLead"]: isClientLead,
          ["client_type"]: client_type,
          ["isTherePALawyer"]: istherelawyer,
          ["domainInLaw"]: domainInLaw,
          ["neoid"]: client_id,
          ["dynamicFormAllData"]: response.NdiFieldsOne.data,
        });
        this.putOnHotTicketData(response.NdiFieldsOne.data);
        return response;
      })
      .catch(function (error) {
        window.alert(error);
      });
  };

  putOnHotTicketData = (dynamicFormAllData) => {
    const mediaToSendToList = [];
    let clientFName = "",
      clientLName = "",
      clientPhone = "",
      clientEmail = "";
    let paFName = "",
      paLName = "",
      paPhone = "",
      paEmail = "";
    let mList =
      dynamicFormAllData.optionsData.length > 0 &&
      dynamicFormAllData.optionsData.map((item, i) => {
        //   const firstname=item.firstname;
        let product_field_id = item.product_field_id;
        if (product_field_id === "75") {
          clientFName = "" + item.field_data;
        } else if (product_field_id === "76") {
          clientLName = "" + item.field_data;
        } else if (product_field_id === "77") {
          clientPhone = "" + item.field_data;
        } else if (product_field_id === "78") {
          clientEmail = "" + item.field_data;
        } else if (product_field_id === "82") {
          paFName = "" + item.field_data;
        } else if (product_field_id === "83") {
          paLName = "" + item.field_data;
        } else if (product_field_id === "84") {
          paPhone = "" + item.field_data;
        } else if (product_field_id === "85") {
          paEmail = "" + item.field_data;
        }

        return null;
      }, this);

    // if (clientPhone !== "") {
    //   mediaToSendToList.push({
    //     role: "client",
    //     fname: clientFName,
    //     lname: clientLName,
    //     media: "SMS",
    //     endmedia: clientPhone,
    //   });
    // }
    if (clientEmail !== "") {
      mediaToSendToList.push({
        role: "client",
        fname: clientFName,
        lname: clientLName,
        media: "email",
        endmedia: clientEmail,
      });
    }

    let mList2 =
      dynamicFormAllData.optionsData.length > 0 &&
      dynamicFormAllData.optionsData.map((mainItem, i) => {
        if (mainItem.product_field_id === "159") {
        } else if (mainItem.product_field_id === "160") {
          if (
            mainItem.field_data_raw !== null &&
            mainItem.field_data_raw !== ""
          ) {
            const cliAds = JSON.parse(mainItem.field_data_raw);
            for (let cliAd of cliAds) {
              let clientxFName = "",
                clientxLName = "",
                clientxPhone = "",
                clientxEmail = "";
              for (let item of cliAd) {
                let product_field_id = item.labelnodel;
                if (product_field_id === 1262) {
                  clientxFName = "" + item.value;
                } else if (product_field_id === 1261) {
                  clientxLName = "" + item.value;
                } else if (product_field_id === 1263) {
                  clientxPhone = "" + item.value;
                } else if (product_field_id === 1264) {
                  clientxEmail = "" + item.value;
                }
              }
              // if (clientxPhone !== "") {
              //   mediaToSendToList.push({
              //     role: "client+",
              //     fname: clientxFName,
              //     lname: clientxLName,
              //     media: "SMS",
              //     endmedia: clientxPhone,
              //   });
              // }
              if (clientxEmail !== "") {
                mediaToSendToList.push({
                  role: "client+",
                  fname: clientxFName,
                  lname: clientxLName,
                  media: "email",
                  endmedia: clientxEmail,
                });
              }
            }
          }
        }
        return null;
      }, this);

    if (mediaToSendToList.length > 0) {
      this.setState({ ["mediaToSendToList"]: mediaToSendToList });
    }
  };
}

const mapStateToProps = (state) => ({ NdiFields: state.NdiFields });

const mapDispatchToProps = {
  getDocLinkType,
  sendDocLink,
  getDocLink,
  getDownloadFileLink,
  getProductFields,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(PublicLinkForm));
